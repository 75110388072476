import { LandingTypeEnum, EmailTypeEnum, PaymentTypeEnum, AdditionTypeEnum, AdditionPurchaseTypeEnum, ThanksTypeEnum } from 'src/app/common/onboarding/public-api';
import { Variant } from './../models/variant';

export const DEFAULT_VARIANT: Variant = {
  abtest: 'Default from Front',
  "id": "fd5f8cf6-4977-43db-b76f-8f844e333f29",
  "name": "Default from Front",
  ab_test_start_event: '',
  
  "onboarding": {
    "id": "fabb30cf-22e1-4f1b-bcac-feddf2ef67a2",
    "name": "OB Var_B_Test [loadder]",
    "screens": [
      {
        "order": 1,
        "info": {
          "id": "1a1efd5f-0c89-453e-9676-d40c9d09a138",
          "type": LandingTypeEnum.AGE_PICTURE,

          "parameters": {
            "ages": [],
            "logo": true,
            "name": "Tiktok001",
            "pictures": [],
            "questions": [],
            "result": [],
            "stepHidden": true,
            "subjects": [],
            "title": "Keiki_AgePicture_1-1",
            "withoutButton": true,
            "ab_test_start_name": '',
          },
          "updated_at": 1700651286314,
          "locked": true
        }
      },
      {
        "order": 2,
        "info": {
          "id": "d454d9ce-b2d5-4db0-8900-fe95bab7e46e",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "Tiktok002",
            "picture": "alphabet2",
            "pictures": [],
            "question": "tiktok_knowledge",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "BM_CanSingAlphabet"
          },
          "updated_at": 1690358096706,
          "locked": false
        }
      },
      {
        "order": 3,
        "info": {
          "id": "98138afe-010a-4c85-9842-35fd9b4c020c",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok003",
            "pictures": [],
            "question": "tiktok_knowledge",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "Can_your_child_write"
          },
          "updated_at": 1690358105005,
          "locked": false
        }
      },
      {
        "order": 4,
        "info": {
          "id": "64a7cbd5-073d-4d6a-9875-843904940108",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok004",
            "pictures": [],
            "question": "tiktok_knowledge",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "Can_your_child_read"
          },
          "updated_at": 1690358113064,
          "locked": false
        }
      },
      {
        "order": 5,
        "info": {
          "id": "f396ada0-73cb-4f51-9774-f9d0c3fb40aa",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok005",
            "picture": "animals2",
            "pictures": [],
            "question": "tiktok_knowledge",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "BM_CanCountAnimals"
          },
          "updated_at": 1690358121309,
          "locked": false
        }
      },
      {
        "order": 6,
        "info": {
          "id": "6d704c6f-ed3c-4b92-a855-535617fdb1a5",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok006",
            "pictures": [],
            "question": "tiktok_knowledge",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "Can_your_child_do_math"
          },
          "updated_at": 1690358129620,
          "locked": false
        }
      },
      {
        "order": 7,
        "info": {
          "id": "30e838a6-2b96-436c-8a89-3630e9aec5c2",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok007",
            "pictures": [],
            "question": "tiktok_knowledge",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "Does_your_child_recognize"
          },
          "updated_at": 1690358136012,
          "locked": false
        }
      },
      {
        "order": 8,
        "info": {
          "id": "386b74f4-ca83-4f66-872c-a3ee6db014d2",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok008",
            "picture": "puzzles2",
            "pictures": [],
            "question": "tiktok_knowledge",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "BM_CanCompletePuzzles"
          },
          "updated_at": 1690358143417,
          "locked": false
        }
      },
      {
        "order": 9,
        "info": {
          "id": "f124bf76-c203-4aa2-8827-857e2e4132bd",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok009",
            "pictures": [],
            "question": "tiktok_knowledge",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "Can_your_child_match"
          },
          "updated_at": 1690358183339,
          "locked": false
        }
      },
      {
        "order": 10,
        "info": {
          "id": "e14c986a-18be-421a-a2db-c7041a607d91",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok010",
            "pictures": [],
            "question": "tiktok_knowledge",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "Can_your_child_patterns"
          },
          "updated_at": 1690358190543,
          "locked": false
        }
      },
      {
        "order": 11,
        "info": {
          "id": "b1dc1879-5f64-4d18-ac62-b343e5d17dd1",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok011",
            "picture": "rainbow",
            "pictures": [],
            "question": "tiktok_knowledge",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "BM_7coulours"
          },
          "updated_at": 1690358196620,
          "locked": false
        }
      },
      {
        "order": 12,
        "info": {
          "id": "5ad7bfb9-3fa1-47b9-a3ac-49a96dfcd814",
          "type": LandingTypeEnum.PERSONALIZED,
           
          "parameters": {
            "ages": [],
            "buttonBg": "dark-blue",
            "buttonText": "Button_Continue",
            "logo": true,
            "name": "tiktok012",
            "picture": "cat",
            "pictures": [],
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "tiktok_expertsRecommend",
            "title": "tiktok_honest"
          },
          "updated_at": 1690358203400,
          "locked": false
        }
      },
      {
        "order": 13,
        "info": {
          "id": "81dea38e-eaf1-4553-af6d-454071f81010",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok013",
            "pictures": [],
            "question": "tiktok_imagination",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "If_you_show_your_story"
          },
          "updated_at": 1690358209672,
          "locked": false
        }
      },
      {
        "order": 14,
        "info": {
          "id": "d8e95949-7394-4d6b-8f8a-a2e60e19f935",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok014",
            "picture": "spyglass",
            "pictures": [],
            "question": "tiktok_imagination",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "tiktok_pretendGame"
          },
          "updated_at": 1690358215841,
          "locked": false
        }
      },
      {
        "order": 15,
        "info": {
          "id": "99d0970a-8174-4019-9115-33f1937421dd",
          "type": LandingTypeEnum.SUBJECTS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok015",
            "oneAnswer": true,
            "pictures": [],
            "question": "tiktok_imagination",
            "questions": [],
            "result": [],
            "subjects": [
              "tiktok_practiseRegularly",
              "tiktok_onceWeek",
              "tiktok_notVeryOften"
            ],
            "text": "tiktok_readBooks",
            "withoutButton": true
          },
          "updated_at": 1690358222484,
          "locked": false
        }
      },
      {
        "order": 16,
        "info": {
          "id": "488eb044-51d2-4638-b9d8-fe02b21ce2fe",
          "type": LandingTypeEnum.PERSONALIZED,
           
          "parameters": {
            "ages": [],
            "buttonText": "Button_Continue",
            "logo": true,
            "name": "tiktok016",
            "picture": "rocket",
            "pictures": [],
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "tiktok_individualLP",
            "title": "tiktok_developing"
          },
          "updated_at": 1690358229503,
          "locked": false
        }
      },
      {
        "order": 17,
        "info": {
          "id": "34102d3f-00e7-48f4-95f4-c77252d9d060",
          type: LandingTypeEnum.SUBJECTS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok017",
            "oneAnswer": true,
            "pictures": [],
            "question": "tiktok_LP",
            "questions": [],
            "result": [],
            "subjects": [
              "Yes_we_practice",
              "A_little_bit",
              "No_were_just_ge"
            ],
            "text": "Does_your_child",
            "withoutButton": true
          },
          "updated_at": 1690358236088,
          "locked": false
        }
      },
      {
        "order": 18,
        "info": {
          "id": "eb9a64b3-5595-4629-bb14-ba3a77ea8df2",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "aboveTitleText": "My_child_has_too_much_screentime",
            "ages": [],
            "logo": true,
            "name": "tiktok018",
            "pictures": [],
            "question": "tiktok_LP",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "Does_this_statement_apply"
          },
          "updated_at": 1690358248749,
          "locked": false
        }
      },
      {
        "order": 19,
        "info": {
          "id": "9832d250-196b-4bb5-b361-f3cc4e765ec4",
          type: LandingTypeEnum.SUBJECTS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok019",
            "oneAnswer": true,
            "pictures": [],
            "question": "tiktok_LP",
            "questions": [],
            "result": [],
            "subjects": [
              "BM_Everyday",
              "BM_FewTimes",
              "tiktok_almostNever"
            ],
            "text": "BM_HowOftenTantrums",
            "withoutButton": true
          },
          "updated_at": 1690358256262,
          "locked": false
        }
      },
      {
        "order": 20,
        "info": {
          "id": "871981db-d84f-432a-807b-e3e65e17ec57",
          type: LandingTypeEnum.SUBJECTS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok020",
            "oneAnswer": true,
            "pictures": [],
            "question": "tiktok_LP",
            "questions": [],
            "result": [],
            "subjects": [
              "tiktok_mostOfTime",
              "tiktok_allMyTime",
              "tiktok_severalHours",
              "tiktok_notMuch"
            ],
            "text": "BM_HowMuchSpend",
            "withoutButton": true
          },
          "updated_at": 1690358262933,
          "locked": false
        }
      },
      {
        "order": 21,
        "info": {
          "id": "827e55c5-cc58-43e7-b94f-f092b77fefd1",
          type: LandingTypeEnum.SUBJECTS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok021",
            "oneAnswer": true,
            "pictures": [],
            "question": "tiktok_LP",
            "questions": [],
            "result": [],
            "subjects": [
              "BM_Fluently",
              "BM_Intermintently",
              "BM_Shy",
              "BM_Quietly",
              "BM_Loudly"
            ],
            "text": "BM_HowSpeak",
            "withoutButton": true
          },
          "updated_at": 1690358268886,
          "locked": false
        }
      },
      {
        "order": 22,
        "info": {
          "id": "d1f5210b-6c64-415a-9e95-3e97ce6edf42",
          "type": LandingTypeEnum.PICTURE_FIRST,
           
          "parameters": {
            "ages": [],
            "buttonText": "Button_Continue",
            "logo": true,
            "name": "tiktok022",
            "picture": "CAT",
            "pictures": [],
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "tiktok_nowDiveInto",
            "title": "tiktok_almostThere"
          },
          "updated_at": 1690358280395,
          "locked": false
        }
      },
      {
        "order": 23,
        "info": {
          "id": "943707c0-6dbc-4b56-9812-9d5b57d24ca5",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok023",
            "pictures": [],
            "question": "tiktok_childProfile",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "Is_it_easy_make_friend"
          },
          "updated_at": 1690358288271,
          "locked": false
        }
      },
      {
        "order": 24,
        "info": {
          "id": "ec6210df-86d4-4898-9c79-987631d5de94",
          type: LandingTypeEnum.SUBJECTS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok024",
            "oneAnswer": true,
            "pictures": [],
            "question": "tiktok_childProfile",
            "questions": [],
            "result": [],
            "subjects": [
              "A_With_others",
              "A_Independently"
            ],
            "text": "What_is_your_way_to_play",
            "withoutButton": true
          },
          "updated_at": 1690358295090,
          "locked": false
        }
      },
      {
        "order": 25,
        "info": {
          "id": "e7f42a67-9adc-4968-be79-b61ab91758c7",
          "type": LandingTypeEnum.TWO_BUTTONS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok025",
            "pictures": [],
            "question": "tiktok_childProfile",
            "questions": [],
            "result": [],
            "subjects": [],
            "text": "Can_your_child_use_phone"
          },
          "updated_at": 1690358300877,
          "locked": false
        }
      },
      {
        "order": 26,
        "info": {
          "id": "6dddb6a8-ae5b-4baf-a978-8aa550020081",
          type: LandingTypeEnum.SUBJECTS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok026",
            "oneAnswer": true,
            "pictures": [],
            "question": "tiktok_childProfile",
            "questions": [],
            "result": [],
            "subjects": [
              "BM_NewGamesActivities",
              "BM_EducationPreparation",
              "BM_VariantsOfRoutine"
            ],
            "text": "BM_WhatAreLooking",
            "withoutButton": true
          },
          "updated_at": 1690358310669,
          "locked": false
        }
      },
      {
        "order": 27,
        "info": {
          "id": "e8231bb2-6380-45ce-a8c2-f422a15cbc0a",
          type: LandingTypeEnum.SUBJECTS,
           
          "parameters": {
            "ages": [],
            "logo": true,
            "name": "tiktok027",
            "oneAnswer": true,
            "pictures": [],
            "question": "tiktok_childProfile",
            "questions": [],
            "result": [],
            "subjects": [
              "BM_Videos",
              "BM_Games",
              "BM_ReadingBooks",
              "BM_Offline"
            ],
            "text": "BM_WhichStyle",
            "withoutButton": true
          },
          "updated_at": 1690358316025,
          "locked": false
        }
      },
      {
        "order": 28,
        "info": {
          "id": "c2b1c6bb-1e49-45ec-92c6-54aabffca4ba",
          "type": LandingTypeEnum.HURT,
          "parameters": {
            "ages": [],
            "buttonText": "Button_Continue",
            "logo": true,
            "name": "tiktok028",
            "pictures": [],
            "question": "tiktok_supportGrowth",
            "questions": [],
            "result": [],
            "subjects": [
              "200+_fun_and_enga",
              "tiktok_IKLearning",
              "Games_that_teach_"
            ]
          },
          "updated_at": 1690358321017,
          "locked": false
        }
      },
      {
        "order": 29,
        "info": {
          "id": "0f8662c0-c756-4a08-8daf-f4b4fed97e59",
          "type": LandingTypeEnum.LOADING_TRIPLE_PROGRESS,
          "parameters": {
            "ages": [],
            "duration": 10000,
            "hideGoBackBtn": true,
            "logo": true,
            "name": "tiktok_newLoading",
            "noCountForStep": true,
            "pictures": [],
            "questions": [],
            "result": [],
            "stepHidden": true,
            "subjects": []
          },
          "updated_at": 1697709632874,
          "locked": false
        }
      }
    ],
  },
  "payment_screen": {
    "id": "f54da874-b934-44a1-925d-692d58c9e33c",
    "name": "Radio v_1 ups_1 no-special",
    screens: null,
    "parameters": {
      "needApple": true,
      "paymaxis": false,
      "prevScreens": [
        {
          "info": {
            "id": "32801087-ccac-4dba-9d86-2b27bafa2d0b",
            "locked": false,
            "parameters": {
              "buttonText": "BM_Continue",
              "logo": true,
              "name": "tiktok030",
              "stepHidden": true,
              "title": "onboarding_screenEmail_header"
            },
            "type": EmailTypeEnum.EMAIL_SHORT,
            "updated_at": 1690358335651
          },
          "order": 1
        },
        {
          "info": {
            "id": "f7337e86-a194-4f4d-bb24-9feb7bd3a714",
            "locked": false,
            "parameters": {
              "ages": [],
              "buttonBg": "marina800",
              "buttonText": "Button_Continue",
              "hideGoBackBtn": true,
              "logo": true,
              "name": "tiktok031",
              "picture": "girl-gamer",
              "pictures": [],
              "question": "1/3",
              "questions": [],
              "result": [],
              "subjects": [],
              "text": "tiktok_IKLearning",
              "title": "WhatDoIGet_title"
            },
            "type": LandingTypeEnum.PERSONALIZED,
            "updated_at": 1690358340843
          },
          "order": 2
        },
        {
          "info": {
            "id": "7182337e-9699-4bd1-98f6-49c7e72efe63",
            "locked": false,
            "parameters": {
              "ages": [],
              "buttonBg": "marina800",
              "buttonText": "Button_Continue",
              "hideGoBackBtn": true,
              "logo": true,
              "name": "tiktok032",
              "picture": "wyg2",
              "pictures": [],
              "question": "2/3",
              "questions": [],
              "result": [],
              "subjects": [],
              "text": "tiktok_ModernApproaches",
              "title": "WhatDoIGet_title"
            },
            "type": LandingTypeEnum.PERSONALIZED,
            "updated_at": 1690358346567
          },
          "order": 3
        },
        {
          "info": {
            "id": "8b726de2-c3ec-49d8-a3ce-d06962d9256c",
            "locked": false,
            "parameters": {
              "ages": [],
              "blink": false,
              "buttonBg": "marina800",
              "buttonText": "Button_Continue",
              "duration": 0,
              "flex": false,
              "hideGoBackBtn": true,
              "logo": true,
              "name": "tiktok033",
              "noCountForStep": false,
              "notFixed": false,
              "oneAnswer": false,
              "percent": 0,
              "picture": "skills",
              "pictures": [],
              "question": "3/3",
              "questions": [],
              "redColor": false,
              "requiredAnswer": false,
              "result": [],
              "staticButton": false,
              "stepHidden": false,
              "subjects": [],
              "text": "7-week_learning_plan",
              "title": "WhatDoIGet_title",
              "withNumbering": false,
              "withoutButton": false,
              "withoutMaybe": false
            },
            "type": LandingTypeEnum.PERSONALIZED
          },
          "order": 4
        },
        {
          "info": {
            "id": "3c99d0d1-1e77-4219-b4ba-e02676dec563",
            "locked": false,
            "parameters": {
              "ages": [],
              "buttonText": "Button_Continue",
              "logo": true,
              "name": "tiktok034",
              "pictures": [],
              "questions": [],
              "result": [],
              "subjects": [
                "Fun_&_engaging_ac",
                "Many_learning_for",
                "Regularly_updated"
              ],
              "title": "Why_Kids_Love_Lea"
            },
            type: LandingTypeEnum.GROW,
            "updated_at": 1690358359790
          },
          "order": 5
        },
        {
          "info": {
            "id": "27563956-6d61-4241-bfd5-385d269d499e",
            "locked": false,
            "parameters": {
              "ages": [],
              "buttonBg": "marina800",
              "buttonText": "Button_Continue",
              "hideGoBackBtn": true,
              "logo": true,
              "name": "tiktok_new048",
              "noCountForStep": true,
              "pictures": [],
              "questions": [],
              "result": [],
              "subjects": [
                "new_review_1",
                "new_review_2",
                "new_review_3"
              ],
              "text": "prepare_with_IKLG",
              "title": "loader_enjoed"
            },
            type: LandingTypeEnum.REVIEWS_FREE,
            "updated_at": 1697709662979
          },
          "order": 6
        },
        {
          "info": {
            "id": "15ed8c85-58be-4aa3-950b-03da7dcff2e1",
            "locked": false,
            "parameters": {
              "cardType": "CARD",
              "crossOnTheLeft": true,
              "firstTimeOffers": [
                {
                  "buttonText": "specialOffer_startTrial",
                  "description": "7_day_trial",
                  "discount": "total_amount_trial_vat",
                  "fakePercent": 0,
                  "fakePrice": 0,
                  "list": [
                    "extended_quaterly"
                  ],
                  "needSpecialText": false,
                  "order": 1,
                  "paymentFormDescription": "per_day",
                  "price": "{TrialPrice}",
                  "productId": "6bb1e05f-3502-4f41-b97a-567232aca46b",
                  "selected": true,
                  "textForSolid": "offerDisclamer_3DayTrial",
                  "textForSolidVat": "offerDisclamer_3DayTrial",
                  "totalPrice": "{TotalPrice}",
                  "underPrice": "users_stay"
                },
                {
                  "advantage": "radio_badge_mostPopular",
                  "buttonText": "get_my_plan",
                  "description": "one_month",
                  "discount": "total_amount_trial_vat",
                  "fakePercent": 0,
                  "fakePrice": 0,
                  "list": [
                    "extended_monthly"
                  ],
                  "needSpecialText": false,
                  "order": 2,
                  "paymentFormDescription": "per_day",
                  "price": "{TrialPrice}",
                  "productId": "f68d927f-1d96-45e3-9128-adc8ded4184e",
                  "selected": false,
                  "textForSolid": "offerDisclamer_withoutTrial1M",
                  "textForSolidVat": "offerDisclamer_withoutTrial1M",
                  "totalPrice": "{TotalPrice}",
                  "underPrice": "users_stay"
                },
                {
                  "advantage": "radio_badge_bestprice",
                  "buttonText": "get_my_plan",
                  "description": "three_month",
                  "discount": "extended_monthly_after_trial",
                  "fakePercent": 0,
                  "fakePrice": 0,
                  "list": [
                    "extended_monthly"
                  ],
                  "needSpecialText": false,
                  "order": 3,
                  "price": "radio_justPrice",
                  "productId": "aa6ccd53-0603-4681-9257-83f3a807c3c5",
                  "selected": false,
                  "textForSolid": "offerDisclamer_withoutTrial",
                  "textForSolidVat": "disclaimerVATTrial",
                  "totalPrice": "{TotalPrice}",
                  "underPrice": "users_stay"
                }
              ],
              "logo": true,
              "methodsType": "THREE",
              "name": "SelectRadio v_1 cross left",
              "secondTimeOffers": [
                {
                  "advantage": "save_55",
                  "buttonText": "get_my_plan",
                  "description": "{TrialPricePerDay}",
                  "discount": "total_amount_trial",
                  "list": [
                    "extended_quaterly"
                  ],
                  "needSpecialText": false,
                  "order": 1,
                  "paymentFormDescription": "per_day",
                  "paymentText": "you_can_save_3_month",
                  "price": "three_month",
                  "productId": "aa6ccd53-0603-4681-9257-83f3a807c3c5"
                }
              ],
              "secondTitle": "choose_your_plan",
              "text": "bigland_title2",
              "title": "bigland_title1"
            },
            "type": PaymentTypeEnum.SELECT_CHECKBOX,
            "updated_at": 1698774909535
          },
          "order": 7
        },
        {
          "info": {
            "id": "517974c4-61d1-4c05-92aa-89f55c27249a",
            "locked": true,
            "parameters": {
              "logo": false,
              "name": "AdditionRadio v_1",
              "offer": [
                {
                  "buttonText": "specialOffer_startTrial",
                  "description": "7_day_trial",
                  "discount": "extended_monthly_7daytrial",
                  "fakePercent": 50,
                  "fakePrice": 49.99,
                  "list": [
                    "offerVAT_extendedQuarterly"
                  ],
                  "needSpecialText": false,
                  "order": 1,
                  "paymentFormDescription": "per_day",
                  "price": "{TrialPrice}",
                  "productId": "e82b1a83-5bed-4bcb-b59b-020fd386f9ad",
                  "selected": true,
                  "textForSolid": "offerDisclamer_3DayTrial",
                  "textForSolidVat": "disclaimerVATTrial",
                  "totalPrice": "{TotalPrice}",
                  "underPrice": "users_stay"
                },
                {
                  "advantage": "radio_badge_mostPopular",
                  "buttonText": "get_my_plan",
                  "description": "one_month",
                  "discount": "<del>{price}</del> {TrialPrice}",
                  "fakePercent": 50,
                  "fakePrice": 24.99,
                  "list": [
                    "offerVAT_extendedQuarterly"
                  ],
                  "needSpecialText": false,
                  "order": 2,
                  "paymentFormDescription": "per_day",
                  "price": "{OldPrice} {TrialPrice}",
                  "productId": "02169800-4fe6-4924-b06d-88cec1f51229",
                  "selected": false,
                  "textForSolid": "offerDisclamer_withoutTrial1M",
                  "textForSolidVat": "disclaimerVAT",
                  "thirdLeft": "popupVAT_VATline",
                  "totalPrice": "{TotalPrice}",
                  "underPrice": "users_stay"
                },
                {
                  "advantage": "radio_badge_bestprice",
                  "buttonText": "get_my_plan",
                  "description": "three_month",
                  "discount": "<del>{price}</del> {TrialPrice}",
                  "fakePercent": 55,
                  "fakePrice": 59.99,
                  "list": [
                    "offerVAT_extendedMonthlyAfter3Days"
                  ],
                  "needSpecialText": false,
                  "order": 3,
                  "paymentFormDescription": "per_day",
                  "price": "radio_justNewPrice",
                  "productId": "0847f652-38a9-47d8-bf8e-d05beae9471d",
                  "selected": false,
                  "textForSolid": "offerDisclamer_withoutTrial",
                  "textForSolidVat": "disclaimerVAT",
                  "totalPrice": "{TotalPrice}",
                  "underPrice": "users_stay"
                }
              ],
              "trialOn": false
            },
            "type": AdditionTypeEnum.ADDITION_CHECKBOX,
          },
          "order": 8
        },
        {
          "info": {
            "id": "a8b9943c-10b7-412c-a103-cae4419be164",
            "locked": false,
            "parameters": {
              "additionalPackages": [],
              "logo": true,
              "name": "E4K_NEW",
              "oxfordProductId": "e81e2095-aeb6-4286-b15c-50f17c512c8c",
              "secondPackages": [],
              "secondProductId": "57e91957-dadd-4f14-85ca-4604e74b9d6c"
            },
            type: AdditionPurchaseTypeEnum.E4K,
            "updated_at": 1688640669891
          },
          "order": 9
        },
        {
          "info": {
            "id": "c767d8fe-ebd4-4fa5-a04a-e971a7ce1b4a",
            "locked": false,
            "parameters": {
              "additionalPackages": [
                {
                  "name": "art_pack",
                  "productId": "71a9e0ad-add7-4b91-9293-540f24807125"
                },
                {
                  "name": "math_pack_title",
                  "productId": "28b8b4a7-7980-4a72-9097-2c21a845a1d3"
                },
                {
                  "name": "speech_pack_title",
                  "productId": "5b2da3ff-5d3e-453d-bdda-0364a7d43779"
                },
                {
                  "name": "dino_pack_title",
                  "productId": "ea3fa674-0f6b-4eae-9e03-ea937bd2ae3f"
                },
                {
                  "name": "winter_pack",
                  "productId": "f7e2ab0f-9cf2-4676-bc54-2a0b446f16a8"
                }
              ],
              "logo": true,
              "name": "BM37",
              "secondPackages": [
                {
                  "name": "art_pack",
                  "productId": "bbd556ea-1148-4b85-9488-85dbc6ca8f86"
                },
                {
                  "name": "math_pack_title",
                  "productId": "38690263-90ae-4e5d-80b8-b089250408d6"
                },
                {
                  "name": "speech_pack_title",
                  "productId": "28c09711-77df-4f3f-a0e6-81dfa3289d80"
                },
                {
                  "name": "dino_pack_title",
                  "productId": "a8492b48-6487-4967-9364-296ef6d7af1c"
                },
                {
                  "name": "winter_pack",
                  "productId": "8851fb20-01ee-46ca-b2a9-13590ee672fd"
                }
              ]
            },
            "type": AdditionPurchaseTypeEnum.WORKSHEETS_2_SCREENS,
            "updated_at": 1684936569294
          },
          "order": 10
        },
        {
          "info": {
            "id": "7d136e33-09f6-42e4-a88a-c777d6455e20",
            "locked": true,
            "parameters": {
              "additionalPackages": [],
              "hideGoogle": false,
              "logo": true,
              "name": "INFO v_3",
              "oxfordFirst": false,
              "packagesInShortPopup": false,
              "secondPackages": [],
              "testTwoTypesOfPackages": false,
              "usePopupInTheLastScreen": false
            },
            "type": ThanksTypeEnum.INFO_3,
          },
          "order": 11
        }
      ]
    },
    "locked": false
  },
  "parameters": {
    "listDisabledLocal": []
  },
}
