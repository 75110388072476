export enum OfferTypeEnum {
  OFFER_CHECKBOX = 'OFFER_CHECKBOX',
  OFFER_SKILLS = 'OFFER_SKILLS',
  OFFER_BIG = 'OFFER_BIG',
  OFFER_BLUE = 'OFFER_BLUE',
  OFFER_GRAY = 'OFFER_GRAY',
  OFFER_CRM_TOP = 'OFFER_CRM_TOP',
  OFFER_CRM_BOTTOM = 'OFFER_CRM_BOTTOM',
  OFFER_CRM_BOTTOM2 = 'OFFER_CRM_BOTTOM2',
}
